// src/components/SobreNosotros.js
import React from 'react';
import './SobreNosotros.css'; // Archivo CSS para los estilos
import Layout from '../components/Layout';
import HeaderWithImage from '../components/HeaderWithImage';

function SobreNosotros() {
    return (
        <Layout>
            {/* Encabezado con imagen de fondo */}
            <HeaderWithImage
                imageSrc="https://cdn.pixabay.com/photo/2015/12/04/14/49/hand-1076597_1280.jpg"
                headerText="Sobre Nosotros"
            />
            
     
     
            <section className="sobre-nosotros-section">

                <p>
                    AssetGlobalToken es una empresa líder en tecnología fintech que se especializa en el desarrollo de soluciones de software para el mundo de las inversiones y la custodia de activos. Su enfoque principal es la tokenización de activos a través de plataformas de negociación automatizadas, lo que permite a los usuarios acceder a una amplia gama de oportunidades de inversión de forma más eficiente y segura.
                </p>
                <p>
                    Con un equipo altamente calificado y una sólida infraestructura tecnológica, AssetGlobalToken está a la vanguardia de la innovación en el sector financiero, brindando soluciones que están transformando la manera en que se invierte y se administra el capital en la era digital.
                </p>

                <div className="sobre-nosotros-content">
                    <div className="sobre-nosotros-image">
                        <img src="https://cdn.pixabay.com/photo/2017/12/05/01/16/handshake-2998302_1280.jpg" alt="Sobre Nosotros 1" />
                    </div>
                    <div className="sobre-nosotros-text">
                        <p>
                            Nuestra empresa se especializa en brindar soluciones de consultoría y análisis, así como herramientas, a instituciones y empresas que buscan evolucionar hacia un futuro digital. Nuestro enfoque se centra en la implantación de tecnología blockchain para que puedan aprovechar al máximo sus beneficios.
                        </p>
                    </div>
                </div>

                <div className="sobre-nosotros-content reverse">
                    <div className="sobre-nosotros-image">
                        <img src="https://cdn.pixabay.com/photo/2015/05/15/01/48/computer-767776_1280.jpg" alt="Sobre Nosotros 2" />
                    </div>
                    <div className="sobre-nosotros-text">
                        <p>
                            La evolución de los sistemas de inversión adaptados a los altos estándares regulatorios, como MIFID en Europa, es fundamental para cumplir con las normas que regulan los mercados de valores. Es importante que las empresas y los inversores se ajusten a estas regulaciones para mantener la transparencia, la seguridad y la confianza en los mercados financieros.
                        </p>
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default SobreNosotros;
