import React from 'react';
import './HeaderWithImage.css';

const HeaderWithImage = ({ imageSrc, headerText }) => {
  return (
    <div className="header-container">
      <img
        src={imageSrc}
        alt="Header"
        className="header-background-image"
      />
      <h1 className="header-text">{headerText}</h1>
    </div>
  );
};

export default HeaderWithImage;
