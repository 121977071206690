// src/components/Footer.js
import React from 'react';
import { FaFacebookF, FaTiktok, FaLinkedinIn, FaXing } from 'react-icons/fa'; // Importar íconos de react-icons
import './Footer.css'; // Importar estilos
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-section">
                <h2>Asset Global Token</h2>
                <p>
                    Ofrecemos servicios de tokenización de activos con soluciones integrales que abarcan todas las áreas necesarias para garantizar un proceso 360.
                </p>
                <div className="social-icons">
                    <FaFacebookF />
                    <FaTiktok />
                    <FaLinkedinIn />
                    <FaXing />
                </div>
            </div>

            <div className="footer-section">
                <h3>Contacto</h3>
                <p>AssetGlobalToken</p>
                <p>Avda. Diagonal 468 6°</p>
                <p>08006 Barcelona</p>
                <p>España</p>
                <p>info@assetglobaltoken.com</p>
            </div>

            <div className="footer-section">
                <h3>Enlaces</h3>
                <ul>
                    <li><Link to="/aviso-legal-info-general">Aviso Legal</Link></li>
                    <li><Link to="/politica-de-privacidad">Política de privacidad</Link></li>
                    <li><Link to="/politica-de-cookies">Política de cookies</Link></li>
                    <li><Link to="/terminos-condiciones">Términos y condiciones</Link></li>
                </ul>
            </div>

            <div className="footer-section">
                <h3>Subscríbete a nuestro boletín</h3>
                <form>
                    <input type="email" placeholder="Introduce tu correo electrónico" />
                    <button type="submit">Enviar</button>
                </form>
            </div>
        </footer>
    );
}

export default Footer;
