import React from 'react';
import './Servicios.css';

const serviciosData = [
    {
        imgSrc: "https://cdn.pixabay.com/photo/2020/01/03/00/56/graph-4737109_1280.jpg",
        altText: "Inversión segura y rentable",
        title: "Inversión segura y rentable",
        description: "Nuestra plataforma ofrece una amplia gama de herramientas y funcionalidades que pueden ser adaptadas a las necesidades específicas de cada cliente. Contáctenos hoy para descubrir cómo nuestra plataforma puede beneficiar a su negocio."
    },
    {
        imgSrc: "https://cdn.pixabay.com/photo/2020/03/06/08/00/laptop-4906312_1280.jpg",
        altText: "Gestión simplificada de activos",
        title: "Gestión simplificada de activos",
        description: "Servicio 360, ofrecemos una solución integral para la creación, gestión y comercialización de activos digitales. Nuestro objetivo es facilitar el proceso para que nuestros clientes puedan aprovechar al máximo las oportunidades que ofrece el entorno digital."
    }
];

const Servicios = () => {
    return (
        <div className="servicios-container">
            {serviciosData.map((servicio, index) => (
                <div className="servicio" key={index}>
                    <img src={servicio.imgSrc} alt={servicio.altText} className="servicio-img" />
                    <h3>{servicio.title}</h3>
                    <p>{servicio.description}</p>
                </div>
            ))}
        </div>
    );
};

export default Servicios;
