import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes, FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Importar íconos
import Logo from './Logo';
import './Header.css';

function Header() {
    const [isMobile, setIsMobile] = useState(false);
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false); // Estado para controlar el submenú

    return (
        <header className="header">
            <Logo />
            <nav className={`nav-links ${isMobile ? 'nav-links-mobile open' : ''}`}>
                <Link to="/" onClick={() => setIsMobile(false)}>HOME</Link>

                {/* Soluciones con submenú */}
                <div className="nav-item">
                    <div 
                        className="soluciones-link" 
                        onClick={(e) => {
                            e.preventDefault(); // Evita la recarga completa de la página
                            setIsSubMenuOpen(!isSubMenuOpen);
                        }}>
                        <Link to="/soluciones" onClick={() => setIsMobile(false)}>
                            SOLUCIONES
                        </Link>
                        <span className="arrow-icon">
                            {isSubMenuOpen ? <FaChevronUp /> : <FaChevronDown />}
                        </span>
                    </div>
                    {isSubMenuOpen && (
                        <div className="submenu">
                            <Link to="/plataforma" onClick={() => setIsMobile(false)}>Plataforma</Link>
                            <Link to="/servicios" onClick={() => setIsMobile(false)}>Servicios</Link>
                        </div>
                    )}
                </div>

                <Link to="/tecnologia-custodia" onClick={() => setIsMobile(false)}>TECNOLOGÍA DE CUSTODIA</Link>
                <Link to="/sobre-nosotros" onClick={() => setIsMobile(false)}>SOBRE NOSOTROS</Link>
                <Link to="/blog" onClick={() => setIsMobile(false)}>BLOG</Link>
                <Link to="/contacto" onClick={() => setIsMobile(false)}>CONTACTO</Link>
                
            </nav>
            <button className="mobile-menu-icon" onClick={() => setIsMobile(!isMobile)}>
                {isMobile ? <FaTimes /> : <FaBars />}
            </button>
        </header>
    );
}

export default Header;
