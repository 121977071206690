import React, { useState } from 'react';
import './FormularioTokenizacion.css'; // Archivo CSS para estilos

const FormularioTokenizacion = () => {
  const [formData, setFormData] = useState({
    nombre: '',
    apellidos: '',
    correo: '',
    mensaje: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aquí puedes agregar la lógica para enviar el formulario
    console.log('Datos enviados:', formData);
  };

  return (
    <form className="formulario-container" onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="nombre">Nombre <span className="required">*</span></label>
        <input
          type="text"
          id="nombre"
          name="nombre"
          value={formData.nombre}
          onChange={handleChange}
          placeholder="Nombre"
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="apellidos">Apellidos</label>
        <input
          type="text"
          id="apellidos"
          name="apellidos"
          value={formData.apellidos}
          onChange={handleChange}
          placeholder="Apellidos"
        />
      </div>
      <div className="form-group">
        <label htmlFor="correo">Correo electrónico <span className="required">*</span></label>
        <input
          type="email"
          id="correo"
          name="correo"
          value={formData.correo}
          onChange={handleChange}
          placeholder="Correo electrónico"
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="mensaje">Comentario o mensaje</label>
        <textarea
          id="mensaje"
          name="mensaje"
          value={formData.mensaje}
          onChange={handleChange}
          placeholder="Escribe tu comentario o mensaje aquí..."
          rows="4"
        ></textarea>
      </div>
      <button type="submit" className="form-button">Enviar</button>
    </form>
  );
};

export default FormularioTokenizacion;
