// src/components/ContactoInfo.js
import React from 'react';
import { FaPhoneAlt, FaEnvelope } from 'react-icons/fa'; // Importar íconos
import './ContactoInfo.css'; // Archivo CSS para estilos
import Layout from './Layout';

function ContactoInfo() {
    return (
    
            <section className="contacto-info-section">
                <div className="contacto-info-item">
                    <FaPhoneAlt className="contacto-icon" />
                    <h3>Nuestro teléfono</h3>
                    <p>Teléfono principal: +34</p>
                </div>
                <div className="contacto-info-item">
                    <FaEnvelope className="contacto-icon" />
                    <h3>Nuestro Email</h3>
                    <p>info@assetglobaltoken.com</p>
                </div>
            </section>
     
    );
}

export default ContactoInfo;
