import React from 'react';
import './Banner.css'; // Archivo CSS para los estilos

const Banner = () => {
  return (
    <div className="banner-container">
      <p className="banner-subtitle">Tokeniza activos</p>
      <h1 className="banner-title">PLATAFORMA PERSONALIZADA</h1>
      <p className="banner-description">Adaptamos la plataforma según tus necesidades</p>
    </div>
  );
};

export default Banner;
