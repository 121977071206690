import React from 'react';
import './HeroSection.css'; // Importar el archivo CSS para estilos

function HeroSection() {
    return (
        <div className="hero-container">
            <div className="hero-content">
                <h1>Plataforma de inversión en activos digitales.</h1>
                <p>
                    La tokenización te permite convertir activos físicos en activos digitales, abriendo nuevas oportunidades de inversión y financiamiento. 
                    Con nuestra plataforma, podrás acceder a una red de inversores interesados en proyectos innovadores y prometedores. 
                    
                </p>
                <h3>¡Únete a nosotros y descubre las ventajas de la economía digital!</h3>
                <button className="hero-button">Saber más</button>
            </div>
        </div>
    );
}

export default HeroSection;
