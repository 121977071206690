import React from 'react';
import './Tokenizacion.css'; // Archivo CSS para estilos

const Tokenizacion = () => {
  return (
    <div className="tokenizacion-container">
      <div className="tokenizacion-texto">
        <h1>Plataformas de tokenización.</h1>
        <p>
          <strong>Aprovecha la tokenización de activos.</strong> Aprovecha la tokenización de activos. En el mundo dinámico de las inversiones, la tokenización de activos ha surgido como una fuerza transformadora, democratizando el acceso a una amplia gama de oportunidades de inversión antes inaccesibles. En AssetGlobalToken, ofrecemos soluciones de software innovadoras que empoderan a los inversores a navegar por este panorama en constante evolución y aprovechar al máximo el potencial de la tokenización de activos. ¿Qué te ofrecemos? Nuestras soluciones de software de última generación te brindan las herramientas y la información necesarias para tomar decisiones de inversión informadas y estratégicas en el entorno de la tokenización de activos. Nuestras plataformas de tokenización automatizadas por una programación de alto rendimiento en smart contracts, adaptada a todos los estándares regulatorios, ofrece todas las garantías necesarias. Simplifican el proceso de creación y administración de tokens digitales.
        </p>
      </div>
      <img
        src="https://cdn.pixabay.com/photo/2018/02/17/05/27/business-3159208_1280.jpg" // Reemplaza con la ruta correcta de tu imagen
        alt="Tokenización"
        className="tokenizacion-imagen"
      />
    </div>
  );
};

export default Tokenizacion;
