// src/components/CardSection.js
import React from 'react';
import { FaMoneyBillWave, FaCommentDots, FaLock } from 'react-icons/fa'; // Importar íconos de react-icons
import './CardSection.css'; // Importar estilos

const cardData = [
    {
        icon: <FaMoneyBillWave size={50} color="#3b5998" />,
        title: "Activos",
        description: "Establece las reglas y parámetros que desees, y procede a tokenizar tus activos de manera sencilla y eficiente. No importa el tipo de activo que desees convertir, nuestra plataforma te ofrece la flexibilidad y la confianza que necesitas."
    },
    {
        icon: <FaCommentDots size={50} color="#3b5998" />,
        title: "Personalización",
        description: "Nuestro objetivo es ofrecerte la flexibilidad necesaria para tomar el control de tus inversiones y adaptarlas a tus necesidades individuales. Ya no tienes que conformarte con un enfoque estándar."
    },
    {
        icon: <FaLock size={50} color="#3b5998" />,
        title: "Gestiona",
        description: "Nuestra plataforma te brinda las herramientas necesarias para gestionar de manera eficiente tus activos digitales, asegurándote de que cumplan con las normativas legales y optimizando su rendimiento."
    }
];

function CardSection() {
    return (
        <div className="card-section">
            {cardData.map((card, index) => (
                <Card key={index} icon={card.icon} title={card.title} description={card.description} />
            ))}
        </div>
    );
}

function Card({ icon, title, description }) {
    return (
        <div className="card">
            <div className="card-icon">
                {icon}
            </div>
            <h3>{title}</h3>
            <p>{description}</p>
        </div>
    );
}

export default CardSection;
