// src/components/Home.js
import React from 'react';
import HeroSection from '../components/HeroSection';
import CardSection from '../components/CardSection';
import AccedeCuenta from '../components/AccedeCuenta';
import Servicio from '../components/Servicios';
import Layout from '../components/Layout';

function Home() {
    return (
        <div>
            <Layout >
            <HeroSection />
            <CardSection />
            <Servicio/> 
            </Layout >
        </div>
    );
}

export default Home;
