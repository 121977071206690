import React from 'react';
import Layout from '../components/Layout';
import './PoliticaDePrivacidad.css'; // Import the CSS file
import HeaderWithImage from '../components/HeaderWithImage';

const PoliticaDePrivacidad = () => {
    return (
        <Layout>
            <HeaderWithImage
                    imageSrc="https://cdn.pixabay.com/photo/2019/11/08/10/34/cyber-4610993_1280.jpg"
                    headerText="Política de Privacidad de AssetGlobalToken"
                />
          
            <div className="privacy-policy-container">
                <h2>Política de Privacidad de AssetGlobalToken</h2>
                
                <h3>1. ¿Quién es el responsable del tratamiento de sus datos?</h3>
                <p>
                    El responsable del tratamiento de sus datos personales es AssetGlobalToken, con NIF B72516099 y domicilio social en Barcelona, G.V. Corts Catalanes, número 617. Le informamos que la titularidad del dominio de nuestra web www.assetglobaltoken.com (en adelante el “Sitio Web”) es de AssetGlobalToken. El acceso y/o uso al Sitio Web le atribuye la condición de Usuario (en adelante, el “Usuario”), e implica la aceptación, desde dicho acceso y/o uso, de la presente Política de Privacidad. El Usuario podrá contactar con AssetGlobalToken en la siguiente dirección de correo electrónico: info@assetglobaltoken.com.
                </p>
                
                <h3>2. ¿Por qué AssetGlobalToken está legitimado para llevar a cabo el tratamiento de datos?</h3>
                <p>
                    AssetGlobalToken está legitimado para tratar tus datos con las siguientes bases legales:
                </p>
                <ul>
                    <li><strong>Ejecución de un contrato:</strong> Cuando te registras en nuestra plataforma o adquieres alguno de nuestros servicios, el tratamiento de tus datos es necesario para cumplir con las obligaciones contractuales establecidas.</li>
                    <li><strong>Consentimiento:</strong> En algunos casos, te solicitaremos tu consentimiento explícito para tratar tus datos con fines específicos, como el envío de comunicaciones comerciales. Podrás retirar este consentimiento en cualquier momento.</li>
                    <li><strong>Interés legítimo:</strong> Trataremos tus datos cuando tengamos un interés legítimo, como mejorar nuestros productos y servicios, personalizar tu experiencia de usuario o prevenir el fraude.</li>
                </ul>

                <h3>3. ¿Cuáles son las finalidades del tratamiento de sus datos de carácter personal por parte de AssetGlobalToken?</h3>
                <p>
                    Tus datos personales serán utilizados para las siguientes finalidades:
                </p>
                <ul>
                    <li><strong>Gestión de usuarios:</strong> Crear y gestionar tu cuenta de usuario, permitir el acceso a áreas restringidas del sitio web y prestar los servicios solicitados.</li>
                    <li><strong>Envío de comunicaciones comerciales:</strong> Enviarte información sobre nuestros productos y servicios, promociones y novedades a través de correo electrónico, SMS u otros medios electrónicos equivalentes.</li>
                    <li><strong>Análisis de datos:</strong> Analizar tu comportamiento en nuestro sitio web para mejorar nuestros productos y servicios y personalizar tu experiencia de usuario.</li>
                    <li><strong>Cumplimiento legal:</strong> Cumplir con las obligaciones legales y fiscales aplicables.</li>
                </ul>

                <h3>4. Veracidad de los datos facilitados por los Usuarios y datos de menores</h3>
                <p>
                    El Usuario garantiza que los datos personales facilitados son veraces y se hace responsable de comunicar a AssetGlobalToken cualquier modificación de los mismos. El Usuario responderá, en cualquier caso, de la veracidad de los datos facilitados, reservándose AssetGlobalToken el derecho a excluir de los Servicios registrados a todo Usuario que haya facilitado datos falsos, sin perjuicio de las demás acciones que procedan en Derecho.
                </p>

                <h3>5. Conservación de los datos</h3>
                <p>
                    AssetGlobalToken conservará tus datos personales durante el tiempo necesario para cumplir con las finalidades para las que fueron recogidos, mientras no revoques tu consentimiento o se cumplan los plazos legales de conservación.
                </p>

                <h3>6. Derechos del Usuario en relación con sus datos</h3>
                <p>
                    Tienes derecho a:
                </p>
                <ul>
                    <li><strong>Acceder:</strong> Consultar los datos personales que tenemos sobre ti.</li>
                    <li><strong>Rectificar:</strong> Solicitar la corrección de datos inexactos o incompletos.</li>
                    <li><strong>Suprimir:</strong> Solicitar la eliminación de tus datos cuando ya no sean necesarios.</li>
                    <li><strong>Limitar el tratamiento:</strong> Solicitar la limitación del tratamiento de tus datos en determinadas circunstancias.</li>
                    <li><strong>Oposición:</strong> Oponerse al tratamiento de tus datos por motivos legítimos.</li>
                    <li><strong>Portabilidad:</strong> Solicitar la portabilidad de tus datos a otro responsable del tratamiento.</li>
                </ul>
                <p>Puedes ejercer estos derechos dirigiéndose a info@assetglobaltoken.com.</p>

                <h3>7. Seguridad de los datos</h3>
                <p>
                    AssetGlobalToken ha implementado medidas de seguridad técnicas y organizativas adecuadas para garantizar la confidencialidad, integridad y disponibilidad de tus datos personales. Sin embargo, debes tener en cuenta que la seguridad en Internet no es absoluta y que AssetGlobalToken no puede garantizar la invulnerabilidad de sus sistemas.
                </p>

                <h3>8. Cambios en la política de privacidad</h3>
                <p>
                    AssetGlobalToken se reserva el derecho a modificar esta política de privacidad. Te recomendamos revisar periódicamente esta política para estar informado de cualquier cambio.
                </p>

                <h3>9. Enlaces a terceros</h3>
                <p>
                    Nuestro sitio web puede contener enlaces a sitios web de terceros. AssetGlobalToken no se hace responsable de las prácticas de privacidad de estos sitios web. Te recomendamos que revises las políticas de privacidad de estos sitios antes de proporcionarles tus datos.
                </p>

                <h3>10. Preguntas</h3>
                <p>
                    Si tienes alguna pregunta sobre esta política de privacidad, puedes ponerte en contacto con nosotros a través de info@assetglobaltoken.com.
                </p>

                <h3>11. Aceptación y consentimiento</h3>
                <p>
                    Al utilizar nuestro sitio web, aceptas las condiciones de esta política de privacidad.
                </p>
            </div>
        </Layout>
    );
};

export default PoliticaDePrivacidad;
