// src/components/ContactoCustodia.js
import React, { useState } from 'react';
import './ContactoCustodia.css'; // Asegúrate de agregar estilos para este componente

const ContactoCustodia = () => {
    const [formData, setFormData] = useState({
        nombre: '',
        apellidos: '',
        email: '',
        mensaje: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Lógica para enviar los datos del formulario
        console.log(formData);
    };

    return (
        <div className="contacto-custodia-container">
            <div>
            <h2>Contacto</h2>
            <p>¿Tienes alguna pregunta sobre nuestro dispositivo de custodia en frío?</p>
            <p>¡Contáctanos hoy mismo para obtener más información!</p>
            </div>
            <form onSubmit={handleSubmit} className="contacto-custodia-form">
                <div className="form-group">
                    <label htmlFor="nombre">Nombre *</label>
                    <input
                        type="text"
                        id="nombre"
                        name="nombre"
                        value={formData.nombre}
                        onChange={handleChange}
                        placeholder="Nombre"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="apellidos">Apellidos</label>
                    <input
                        type="text"
                        id="apellidos"
                        name="apellidos"
                        value={formData.apellidos}
                        onChange={handleChange}
                        placeholder="Apellidos"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Correo electrónico *</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Correo electrónico"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="mensaje">Comentario o mensaje</label>
                    <textarea
                        id="mensaje"
                        name="mensaje"
                        value={formData.mensaje}
                        onChange={handleChange}
                        placeholder="Comentario o mensaje"
                    ></textarea>
                </div>
                <button type="submit">Enviar</button>
            </form>
        </div>
    );
};

export default ContactoCustodia;
