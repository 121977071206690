// src/components/Servicios.js
import React from 'react';
import './Servicios.css'; // Archivo CSS para los estilos
import Layout from '../components/Layout';
import HeaderWithImage from '../components/HeaderWithImage';

const serviciosData = [
    {
        titulo: 'Análisis, consultoría, formación e implementación.',
        texto: `Nuestro equipo de expertos está dedicado a ayudar a las empresas a alcanzar sus objetivos a través de la implementación de soluciones tecnológicas efectivas y eficientes.
                Realizamos un análisis exhaustivo de las necesidades de cada empresa para poder ofrecer una consultoría personalizada y adaptada a sus requerimientos específicos. Además, proporcionamos formación especializada para garantizar que el personal esté completamente familiarizado con la tecnología implementada. Nuestra meta es facilitar la transición hacia la tecnología moderna y asegurar que las empresas puedan aprovechar al máximo las ventajas que ofrece.`,
        imagen: 'https://cdn.pixabay.com/photo/2017/12/21/12/08/consulting-3031678_1280.jpg',
    },
    {
        titulo: 'Lanzamiento de proyectos de socios y colaboradores',
        texto: `Trabajamos estrechamente con nuestros socios y colaboradores para garantizar el éxito en el lanzamiento de sus proyectos. Nos comprometemos a brindarles el apoyo necesario en cada etapa del proceso, desde la planificación inicial hasta la ejecución final.`,
        imagen: 'https://cdn.pixabay.com/photo/2018/02/07/20/52/bitcoin-3137984_1280.jpg',
    },
    {
        titulo: 'Servicios financieros descentralizados (DeFi)',
        texto: `Ofrecemos una amplia gama de servicios financieros descentralizados que te permiten acceder a préstamos y créditos en criptomonedas sin depender de los bancos. Nuestros intercambios descentralizados te brindan la oportunidad de comprar y vender criptomonedas de manera segura y directa.`,
        imagen: 'https://cdn.pixabay.com/photo/2018/05/17/21/26/cryptocurrency-3409725_1280.jpg',
    },
    {
        titulo: 'Soluciones de custodia.',
        texto: `Ofrecemos soluciones de custodia de criptoactivos en frío tanto para instituciones como para particulares, con nuestro dispositivo en proceso de inscripción de patente, lo que garantiza la innovación y exclusividad de nuestro sistema.`,
        imagen: 'https://cdn.pixabay.com/photo/2019/12/23/18/00/portugal-4715104_1280.jpg',
    },
];

function Servicios() {
    return (
        <Layout>

            <HeaderWithImage
                imageSrc="https://cdn.pixabay.com/photo/2017/08/23/16/03/io-centers-2673317_1280.jpg"
                headerText="Servicios"
            />

            <section className="servicios-section">

                {serviciosData.map((servicio, index) => (
                    <div key={index} className={`servicio-item ${index % 2 === 0 ? 'left' : 'right'}`}>
                        <div className="servicio-image">
                            <img src={servicio.imagen} alt={servicio.titulo} />
                        </div>
                        <div className="servicio-text">
                            <h3>{servicio.titulo}</h3>
                            <p>{servicio.texto}</p>
                        </div>
                    </div>
                ))}
            </section>
        </Layout>
    );
}

export default Servicios;
