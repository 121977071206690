// src/components/Plataforma.js
import React from 'react';
import './Plataforma.css'; // Importar el archivo de estilos
import Layout from '../components/Layout';
import HeaderWithImage from '../components/HeaderWithImage';

function Plataforma() {
    return (
        <Layout>
                {/* Encabezado con imagen de fondo */}
                <HeaderWithImage
                    imageSrc="https://cdn.pixabay.com/photo/2016/03/31/09/06/lock-1292282_1280.jpg"
                    headerText="Soluciones"
                />
          
          
            <section className="plataforma-section">
                <div className="plataforma-content">
                    <div className="text-block">
                        <p>
                            Nuestra misión es facilitar la creación, gestión y comercialización de activos digitales para empresas y particulares a través de una plataforma de inversión innovadora y segura.
                        </p>
                        <p>
                            Nuestros servicios van más allá de las expectativas, brindando una atención integral y completa para satisfacer todas las necesidades de nuestros clientes. Con un enfoque 360, nos aseguramos de abordar todos los aspectos relevantes para garantizar un servicio de alta calidad y eficiencia. Nuestro compromiso es ofrecer soluciones integrales que superen las expectativas de nuestros clientes, proporcionando un servicio completo y personalizado en cada etapa del proceso.
                        </p>
                    </div>
                    <div className="image-block">
                        <img src="https://cdn.pixabay.com/photo/2016/04/20/08/21/entrepreneur-1340649_1280.jpg" alt="Activos Digitales" />
                    </div>
                    <div className="text-block">
                        <p>
                            Nuestra empresa se compromete a ofrecer a nuestros clientes la máxima seguridad y la innovación tecnológica garantizada. Trabajamos constantemente para asegurar que todas nuestras soluciones están a la vanguardia de la tecnología, brindando a nuestros clientes la tranquilidad de saber que están protegidos por las últimas innovaciones en seguridad.
                        </p>
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default Plataforma;
