import React from 'react';
import './Soluciones.css'; // Archivo CSS para estilos
import Layout from '../components/Layout';
import Tokenizacion from '../components/Tokenizacion';
import Banner from '../components/Banner';
import FormularioTokenizacion from './FormularioTokenizacion';
import VentajasTokenizacion from '../components/VentajasTokenizacion';
import HeaderWithImage from '../components/HeaderWithImage';

const Soluciones = () => {
  return (
    <Layout>
      <HeaderWithImage
        imageSrc="https://cdn.pixabay.com/photo/2018/10/02/20/34/woman-3719880_1280.jpg"
        headerText="Soluciones"
      />



      <Tokenizacion />
      <Banner />
      <FormularioTokenizacion />
      <VentajasTokenizacion />
    </Layout>

  );
};

export default Soluciones;
