// src/components/Blog.js
import React from 'react';
import './Blog.css'; // Archivo CSS para estilos
import Layout from '../components/Layout';
import HeaderWithImage from '../components/HeaderWithImage';

function Blog() {
    return (
        <Layout>
            <section className="blog-section">
                {/* Encabezado con imagen de fondo */}
                <HeaderWithImage
                    imageSrc="https://cdn.pixabay.com/photo/2015/05/31/10/55/man-791049_1280.jpg"
                    headerText="Blog"
                />

                {/* Contenido del blog */}
                <div className="blog-content">
                    <h2>Próximamente</h2>
                </div>
            </section>
        </Layout>
    );
}

export default Blog;
