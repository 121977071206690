import React from 'react';
import './VentajasTokenizacion.css'; // Archivo CSS para estilos

const VentajasTokenizacion = () => {
  return (
    <div className="ventajas-container">
      <p><strong>Mayor accesibilidad:</strong> La tokenización permite que inversores con menor capital puedan acceder a activos que antes eran inaccesibles, como bienes inmuebles, obras de arte o acciones de empresas no cotizadas, entre otros.</p>
      <p><strong>Mayor liquidez:</strong> Los tokens son activos digitales que se pueden comprar y vender en mercados descentralizados las 24 horas del día, los 7 días de la semana...</p>
      <p><strong>Mayor eficiencia:</strong> La tokenización elimina intermediarios y automatiza procesos, lo que reduce costes y aumenta la eficiencia de las transacciones.</p>
      <p><strong>Mayor transparencia:</strong> La tecnología blockchain proporciona un registro inmutable y transparente de todas las transacciones, lo que reduce el riesgo de fraude y aumenta la confianza de los inversores.</p>
      <p><strong>Mayor fraccionamiento:</strong> Los activos pueden dividirse en tokens más pequeños, lo que permite a los inversores invertir en ellos con menos capital.</p>
      <p><strong>Mayor seguridad:</strong> Los tokens se almacenan en la cadena de bloques, una tecnología altamente segura y resistente a la piratería.</p>
      <p><strong>Acceso a nuevas posibilidades de inversión:</strong> La tokenización abre la puerta a nuevas posibilidades de inversión que antes no estaban disponibles para los inversores minoristas.</p>
      <p><strong>Mayor potencial de diversificación:</strong> La tokenización permite a los inversores diversificar sus carteras de inversión con una mayor variedad de activos.</p>
    </div>
  );
};

export default VentajasTokenizacion;
