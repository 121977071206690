// src/components/Home.js
import React from 'react';
import CustodiaFrioHeader from '../components/CustodiaFrioHeader';
import Layout from '../components/Layout';
import CustodiaDetalle from '../components/CustodiaDetalle';
import ContactoCustodia from '../components/ContactoCustodia';
import HeaderWithImage from '../components/HeaderWithImage';

function Home() {
    return (
        <Layout>
        
               
                {/* Encabezado con imagen de fondo */}
                <HeaderWithImage
                    imageSrc="https://cdn.pixabay.com/photo/2016/08/13/16/49/computer-1591018_1280.jpg"
                    headerText="Dispositivo de Custodia en Frío"
                />
             
            <CustodiaDetalle/>
            <ContactoCustodia/>
        </Layout>
    );
}

export default Home;
