// src/components/CustodiaDetalle.js
import React from 'react';
import './CustodiaDetalle.css';


function CustodiaDetalle() {
    return (
        <div className="custodia-detalle-container">
            <div className="custodia-detalle-content">
                <h2>Descubre nuestro dispositivo de custodia en frío.</h2>
                <p>
                    Somos una empresa dedicada a la creación de dispositivos de custodia en frío con aislamiento total para la protección de datos sensibles en cadenas de bloques. Nuestra innovación se basa en la comunicación óptica para garantizar la máxima seguridad.
                </p>
                <p>
                    Nuestros dispositivos han sido diseñados teniendo en consideración todas las regulaciones y estándares para proporcionar el mejor servicio a instituciones bancarias, empresas y usuarios individuales. Nos esforzamos por cumplir con las normativas para garantizar la seguridad y eficiencia en nuestras soluciones, brindando confianza a nuestros clientes.
                </p>
                <p>
                    Nuestra prioridad es ofrecer productos de alta calidad que cumplan con los requerimientos legales y satisfagan las necesidades de nuestros usuarios.
                </p>
            </div>
            <div className="custodia-detalle-image">
            <img src="https://cdn.pixabay.com/photo/2015/11/15/09/11/wall-safe-1044168_1280.jpg" alt="Custodia en Frío" />

            </div>
        </div>
    );
}

export default CustodiaDetalle;
