// src/components/Layout.js
import React, { useEffect, useState } from 'react';
import './Layout.css'; // Importa el archivo CSS para los estilos responsivos
import Header from './Header';
import Footer from './Footer';

export default function Layout({ children }) {
    const [scroll, setScroll] = useState(0);

    useEffect(() => {
        document.addEventListener('scroll', () => {
            const scrollCheck = window.scrollY > 100;
            if (scrollCheck !== scroll) {
                setScroll(scrollCheck);
            }
        });
    }, [scroll]);

    return (
        <div className="layout">
            <Header scroll={scroll} />
            <main className="main-content">{children}</main>
            <Footer />
        </div>
    );
}
