import React from 'react';
import Layout from '../components/Layout';
import './TerminosYCondiciones.css'; // Import the CSS file
import HeaderWithImage from '../components/HeaderWithImage';

const TerminosYCondiciones = () => {
    return (
        <Layout>
              <HeaderWithImage
        imageSrc="https://cdn.pixabay.com/photo/2020/05/30/10/08/general-terms-and-conditions-5238384_1280.jpg"
        headerText="Términos y Condiciones"
      />


            <div className="terms-conditions-container">
               
                
                <h3>1. Identificación</h3>
                <p>
                    AssetGlobalToken, con NIF B72516099 y domicilio social en Barcelona, Carrer de les Corts Catalanes, número 617, es una plataforma tecnológica que ofrece servicios de tokenización de activos a empresas y particulares.
                </p>
                
                <h3>2. Objeto</h3>
                <p>
                    El presente aviso legal regula los términos y condiciones de uso de la plataforma AssetGlobalToken (en adelante, la “sitio web”).
                </p>
                
                <h3>3. Aceptación de las Condiciones</h3>
                <p>
                    La utilización de la Plataforma implica la aceptación plena y sin reservas de las presentes condiciones generales.
                </p>
                
                <h3>4. Propiedad Intelectual</h3>
                <p>
                    Todos los derechos de propiedad intelectual sobre la Plataforma y sus contenidos pertenecen a AssetGlobalToken.
                </p>
                
                <h3>5. Protección de Datos</h3>
                <p>
                    AssetGlobalToken trata los datos personales de los usuarios de conformidad con lo dispuesto en el Reglamento General de Protección de Datos (RGPD) y la Ley Orgánica de Protección de Datos Personales y garantía de los derechos digitales (LOPDGDD).
                </p>
                
                <h3>6. Cookies</h3>
                <p>
                    AssetGlobalToken utiliza cookies para mejorar la experiencia del usuario en el sitio web. La política de cookies se encuentra disponible en [enlace a la política de cookies].
                </p>
                
                <h3>7. Limitación de Responsabilidad</h3>
                <p>
                    AssetGlobalToken no se hace responsable de los daños y perjuicios que puedan derivarse del uso del sitio web.
                </p>
                
                <h3>8. Modificación de las Condiciones</h3>
                <p>
                    AssetGlobalToken se reserva el derecho a modificar las presentes condiciones en cualquier momento.
                </p>
                
                <h3>9. Legislación aplicable y jurisdicción</h3>
                <p>
                    Las presentes condiciones se rigen por la ley española. Cualquier controversia será resuelta por los Juzgados y Tribunales de Barcelona.
                </p>
            </div>
        </Layout>
    );
};

export default TerminosYCondiciones;


