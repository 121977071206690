// src/components/Contacto.js
import React from 'react';
import ContactoHeader from '../components/ContactoHeader';
import ContactoInfo from '../components/ContactoInfo';
import FormularioContacto from '../components/FormularioContacto';
import Layout from '../components/Layout';
import HeaderWithImage from '../components/HeaderWithImage';
function Contacto() {
    return (
        <Layout>
           
        
                {/* Encabezado con imagen de fondo */}
                <HeaderWithImage
                    imageSrc="https://cdn.pixabay.com/photo/2017/12/02/15/17/contact-us-2993109_1280.jpg"
                    headerText="Contacto"
                />
            <ContactoInfo />
            <FormularioContacto />
        </Layout>

    );
}

export default Contacto;
