// src/components/Logo.js
import React from 'react';
import './Logo.css'; // Importar los estilos

function Logo() {
    return (
        <div className="logo-container">
           {/*<h1 className="logo-text">
                Asset Global <span className="highlight">Token</span>
            </h1>
            <div className="logo-decoration">
                <span className="dots">...</span>
                <div className="underline"></div>
            </div> */} 
           <img className="logo-image" src="https://firebasestorage.googleapis.com/v0/b/assetglobaltoken.appspot.com/o/assetglobaltoken%20logo%20(1).png?alt=media&token=8a7ce5bc-23a4-4925-88a0-50c9b4d05101" alt="Logo" />

        </div>
    );
}

export default Logo;
