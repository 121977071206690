import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; 
import Home from './screens/Home';
import Plataforma from './screens/Plataforma';
import Servicios from './screens/Servicios';
import Blog from './screens/Blog';
import SobreNosotros from './screens/SobreNosotros';
import Contacto from './screens/Contacto';
import CustodiaFrio from './screens/CustodiaFrio';
import Soluciones from './screens/Soluciones';

// Importa los nuevos componentes
import AvisoLegalInfoGeneral from './screens/AvisoLegalInfoGeneral';
import UsoDelSitioWeb from './screens/UsoDelSitioWeb';
import PoliticaDePrivacidad from './screens/PoliticaDePrivacidad';
import PoliticaDeCookies from './screens/PoliticaDeCookies';
import './colores.css'
import './fontSize.css'

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/servicios" element={<Servicios />} />
                <Route path="/soluciones" element={<Soluciones />} />
                <Route path="/plataforma" element={<Plataforma />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/sobre-nosotros" element={<SobreNosotros />} />
                <Route path="/contacto" element={<Contacto />} />
                <Route path="/tecnologia-custodia" element={<CustodiaFrio />} />
                
                {/* Nuevas rutas */}
                <Route path="/aviso-legal-info-general" element={<AvisoLegalInfoGeneral />} />
                <Route path="/terminos-condiciones" element={<UsoDelSitioWeb />} />
                <Route path="/politica-de-privacidad" element={<PoliticaDePrivacidad />} />
                <Route path="/politica-de-cookies" element={<PoliticaDeCookies />} />
            </Routes>
        </Router>
    );
}

export default App;
