import React, { useState } from 'react';
import './FormularioContacto.css';

function FormularioContacto() {
    const [formData, setFormData] = useState({
        nombre: '',
        email: '',
        servicios: [],
        mensaje: ''
    });

    const serviciosOptions = [
        'Formación', 'Consultoría', 'Tokeniza tu empresa', 
        'Mercado Privado', 'Mercado Público', 
        'Plataforma', 'Lanzadera de proyectos', 'Otros'
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        let updatedServicios = [...formData.servicios];
        if (checked) {
            updatedServicios.push(value);
        } else {
            updatedServicios = updatedServicios.filter(item => item !== value);
        }
        setFormData({ ...formData, servicios: updatedServicios });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { nombre, email, servicios, mensaje } = formData;
        const serviciosString = servicios.join(', ');
        const mailtoLink = `web@assetglobaltoken.com?subject=Contacto de ${encodeURIComponent(nombre)}&body=Email: ${encodeURIComponent(email)}%0AServicios: ${encodeURIComponent(serviciosString)}%0AMensaje: ${encodeURIComponent(mensaje)}`;
        
        window.location.href = mailtoLink;
    };

    return (
        <form className="contact-form" onSubmit={handleSubmit}>
            <div className="contact-form-group">
                <label>Nombre *</label>
                <input 
                    type="text" 
                    name="nombre" 
                    value={formData.nombre} 
                    onChange={handleChange} 
                    required 
                />
            </div>
            <div className="contact-form-group">
                <label>Correo electrónico *</label>
                <input 
                    type="email" 
                    name="email" 
                    value={formData.email} 
                    onChange={handleChange} 
                    required 
                />
            </div>
            <div className="contact-form-group">
                <label>¿Cuáles de nuestros servicios te interesan?</label>
                {serviciosOptions.map((servicio, index) => (
                    <div key={index} className="checkbox-container-t">
                        <input 
                            type="checkbox" 
                            value={servicio} 
                            onChange={handleCheckboxChange} 
                        />
                        <label>{servicio}</label>
                    </div>
                ))}
            </div>
            <div className="contact-form-group">
                <label>¿Cómo podemos ayudarte? *</label>
                <textarea 
                    name="mensaje" 
                    value={formData.mensaje} 
                    onChange={handleChange} 
                    required 
                />
            </div>
            <button type="submit" className="contact-submit-button">Enviar</button>
        </form>
    );
}

export default FormularioContacto;
