import React from 'react';
import Layout from '../components/Layout';
import HeaderWithImage from '../components/HeaderWithImage';
import './AvisoLegalInfoGeneral.css'; // Import the CSS file


const AvisoLegalInfoGeneral = () => {
    return (
        <Layout>
            <HeaderWithImage
                imageSrc="https://cdn.pixabay.com/photo/2020/03/12/19/36/the-legal-4926021_1280.jpg"
                headerText="Aviso Legal"
            />

            <div className="aviso-legal-container">
                <h2>1. Información General</h2>
                <p>
                    AssetGlobalToken, con NIF B72516099 y domicilio social en Barcelona, Carrer de les Corts Catalanes, número 617, es el titular de este sitio web (www.assetglobaltoken.com).
                </p>
                <p>
                    Al acceder y utilizar este sitio web, el usuario (en adelante, el “Usuario”) acepta íntegramente las condiciones establecidas en este Aviso Legal.
                </p>

                <h2>2. Objeto del Sitio Web</h2>
                <p>
                    AssetGlobalToken ofrece a través de este sitio web [Describir los servicios de forma detallada, por ejemplo: “una plataforma de inversión en criptomonedas que permite a los usuarios comprar, vender y almacenar una amplia variedad de activos digitales de forma segura y eficiente”].
                </p>

                <h2>3. Uso del Sitio Web</h2>
                <p>
                    El Usuario se compromete a utilizar el sitio web de forma lícita y a no realizar acciones que puedan perjudicar la imagen o los intereses de AssetGlobalToken o de terceros. Queda prohibido cualquier uso del sitio web que sea contrario a la ley, la moral o el orden público.
                </p>

                <h2>4. Propiedad Intelectual e Industrial</h2>
                <p>
                    Todos los contenidos del sitio web, incluyendo textos, gráficos, logos, imágenes, etc., son propiedad de AssetGlobalToken o de terceros que han autorizado su uso. Queda prohibida cualquier reproducción, distribución o comunicación pública de los contenidos sin el consentimiento previo y por escrito de AssetGlobalToken.
                </p>

                <h2>5. Responsabilidad</h2>
                <p>
                    AssetGlobalToken ha realizado todos los esfuerzos razonables para garantizar la fiabilidad de la información contenida en este sitio web. Sin embargo, no se responsabiliza de los errores u omisiones que puedan producirse, ni de los daños o perjuicios que puedan derivarse del acceso o uso de este sitio web.
                </p>

                <h2>6. Política de Privacidad</h2>
                <p>
                    La recogida y tratamiento de los datos personales de los usuarios se rige por nuestra Política de Privacidad, que puede consultarse en [inserte el enlace a la política de privacidad].
                </p>

                <h2>7. Cookies</h2>
                <p>
                    Utilizamos cookies para mejorar tu experiencia en nuestro sitio web. Las cookies son pequeños archivos de texto que se almacenan en tu dispositivo. Puedes obtener más información sobre cómo utilizamos las cookies y cómo gestionar tus preferencias en nuestra Política de Cookies.
                </p>

                <h2>8. Responsabilidad del Usuario</h2>
                <p>
                    El Usuario se compromete a:
                </p>
                <ul>
                    <li>Utilizar el sitio web de forma lícita y diligente.</li>
                    <li>No realizar acciones que puedan dañar, inutilizar o sobrecargar el sitio web, o impedir un uso normal del mismo.</li>
                    <li>No introducir, almacenar o difundir en o desde el sitio web cualquier contenido que sea difamatorio, injurioso, obsceno, amenazante, xenófobo, violento, pornográfico, ilegal, o que de cualquier forma atente contra la moral, el orden público, los derechos fundamentales, las libertades públicas, el honor, la intimidad o la imagen de terceros.</li>
                    <li>No utilizar identidades falsas, ni suplantar la identidad de otros, ni tampoco falsear el origen de cualquier comunicación.</li>
                    <li>No utilizar el sitio web con fines comerciales o publicitarios sin autorización previa de AssetGlobalToken.</li>
                    <li>Mantener en secreto y no revelar a terceros las claves de acceso que le sean proporcionadas.</li>
                </ul>

                <h2>9. Gestión de Incidencias</h2>
                <p>
                    Para cualquier consulta o incidencia relacionada con el uso del sitio web, el Usuario puede ponerse en contacto con AssetGlobalToken a través de la siguiente dirección de correo electrónico: info@azure-elk-509048.hostingersite.com.
                </p>
                <p>
                    AssetGlobalToken se compromete a atender todas las incidencias en el menor tiempo posible. Sin embargo, no se garantiza la resolución inmediata de todas las incidencias.
                </p>

                <h2>10. Modificaciones</h2>
                <p>
                    AssetGlobalToken se reserva el derecho a modificar este Aviso Legal en cualquier momento. Te recomendamos que revises periódicamente esta página para estar informado de cualquier cambio.
                </p>

                <h2>11. Legislación aplicable y jurisdicción</h2>
                <p>
                    Las relaciones establecidas entre AssetGlobalToken y el usuario se regirán por la normativa legal española vigente. Para la resolución de cualquier controversia derivada de la prestación de los servicios ofrecidos a través de este sitio web, las partes se someterán a la jurisdicción de los Juzgados y Tribunales de Barcelona, con renuncia expresa a cualquier otro fuero que pudiera corresponderles.
                </p>
            </div>
        </Layout>
    );
};

export default AvisoLegalInfoGeneral;
